import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;
  public logoUrl;
  public company_name;
  public user_name;
  public pages1;
  public icon;

  constructor(private appService: AppService, public data: DataProvider, public rest: CallapiService,) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        if (res.code == 'S000001') {
          this.pages1 = res.data;
          for (let i = 0; i < this.pages1.length; i++) {
            if (this.pages1[i].pagename == 'contract-corp-reference') {
              console_log('contract-corp-reference');
              if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                this.icon = true;
              }
              if (this.pages1[i].auth == 'D') {
                this.icon = false;
              }
            }
          }
        }
      },
    );
    this.rest.Get_LoginData(this.data.uuid, this.data.loggedEmail).subscribe(
      (res) => {
        this.company_name = res.data.company_name;
        this.user_name = res.data.user_name;
      },
      (err) => {
        console_log(err);
      }
    );
  }

  logout() {
    this.appService.logout();
  }
}
