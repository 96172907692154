<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-white navbar-light" style="margin-left: 0px;">
    <a [routerLink]="['/home']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
      /></a>
  </nav>
</div>
<div class="login-box">
  <div class="login-logo">
    <i class='correct'></i>
    <p style="margin-top: -6px;" class="title-login">{{ 'SUCCESS_WARNING.COMPLETE_CHANGE_EMAIL' | translate }}</p>
  </div>
  <div class="text-center" style="padding-top: 24px;">
    <p>{{ 'SUCCESS_WARNING.THE_EMAIL_CHANGE_COMPLETED' | translate }}</p>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > 
    {{ 'COMMON.LOADING' | translate }}</p>>
</ngx-spinner>