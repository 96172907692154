<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'CONTRACT_UNPAID_PAYMENT.TITLE' | translate }}
  </h3>
  <section class="content" id="userdetail" style="display: block;">
    <div class="card-body">
      <p class="left-border">{{ 'CONTRACT_UNPAID_PAYMENT.ACCRUED_PAYMENT_CONFIRMATION' | translate }}</p>
      <p class="card-title">{{ 'CONTRACT_UNPAID_PAYMENT.TITLE_TEXT' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'CONTRACT_UNPAID_PAYMENT.EMPLOYEE_NUMBER' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-11 display">
            <div class="col-10 col-md-12 col-xl-12 display">
               <h3 class="card-title display">{{ User_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'CONTRACT_UNPAID_PAYMENT.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content">{{ User_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'CONTRACT_UNPAID_PAYMENT.MONEY_FOR_PAYMENT' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content"> {{ Unpaid_Amount }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'CONTRACT_UNPAID_PAYMENT.WITHDRAWAL_DATE' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content"> {{ End_Date }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="payment()" class="btn-pink">
            {{ 'CONTRACT_UNPAID_PAYMENT.TD_8_APPLY' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > 
    {{ 'COMMON.LOADING' | translate }}</p>>
</ngx-spinner>