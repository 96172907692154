<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
  </h3>
  <section class="content">
    <div class="card-body">
      <div class="text-center">
        <h3 style="margin-top: 90px; text-align: center" id='title'>
          {{ 'REGISTER_ALL_USER_FAIL.REGISTRATION_WAS_COMPLETE_TEXT_1' | translate }} <br> 
          {{ 'REGISTER_ALL_USER_FAIL.PARTIAL_REGISTRATION_FAILED_TEXT_2' | translate }}
          <!-- <br>
          {{ 'REGISTER_ALL_USER_FAIL.THE_TARGET_IS_AS_FOLLOWS_TEXT_3' | translate }} -->
        </h3>
      </div>
    </div>
    <section class="content"> 
      <div class="container-fluid" style="color: black;">
        <div class="row table-width">
          <table id="example2" class="table table-bordered" style="background-color: white;">
            <thead>
              <tr>
                <th>{{ 'REGISTER_ALL_USER_FAIL.ERROR_LINE_TH' | translate }}</th>
                <th>{{ 'REGISTER_ALL_USER_FAIL.ERROR_CONTENTS_TH' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of error_data">
                <td class="tdata-1">{{list.error_line_number}}</td>
                <td class="tdata-2">{{list.error_code}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="card-body">
      <div class="text-center">
        <p class="card-result">
          {{ 'REGISTER_ALL_USER_FAIL.BACK_BUTTON_COMMENT' | translate }}
        </p>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center button_margin_bottom">
      <div class="col-12 ">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
    <div class="text-center">
      <p class="card-title">
        「<a (click)="changeuserinfo()"  class="text2">
          {{ 'BUTTON_TYPES.REGISTRATION_LIST' | translate }}
          </a>
          {{ 'REGISTER_ALL_USER.VIEW_REGISTERED_CONTENTS_MSG' | translate }}
      </p>
    </div>
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>