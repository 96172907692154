import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CSV_TEMPLATE, IDENTITY_POOL_ID, REGION, S3_BUCKET, S3_FOLDER_PATH, USER_POOL_ID } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import * as AWS from 'aws-sdk';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-bulk-register',
  templateUrl: './bulk-register.component.html',
  styleUrls: ['./bulk-register.component.scss'],
})
export class BulkRegisterComponent implements OnInit {
  public csvfile_name;
  public corporation_no = '';
  public today_date;
  public today;
  public file;
  public csvFileName;
  public fileType;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    public data: DataProvider,
    protected datepipe: DatePipe,
    private translate: TranslateService,
    public rest: CallapiService,) { }

  ngOnInit() {
    this.widthChanger('main-header', 741);
    this.data.menuPageTitle = 'contract-user-bulk-regist';
    this.corporation_no = JSON.parse(localStorage.getItem('loginData')).data.corporation_no;
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }
  
  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.file == null || this.file == '') {
      err = true;
      this.translate
        .get('CSV_VALIDATION.ERROR_FILE')
        .subscribe((res) => (errTxt = res));
    } else if (this.fileType != 'csv') {
      err = true;
      this.translate
        .get('CSV_VALIDATION.ERROR_PLEASE_UPLOAD_THE_CSV_FILE')
        .subscribe((res) => (errTxt = res));
      document.getElementById("csvfile_name").innerHTML = this.csvfile_name;
      this.file = '';
    }
      if (err) {
        document.getElementById('error').innerHTML = errTxt;
        document.getElementById('error').style.display = 'block';
        return false;
      }
    return true;
  }
  
  async upload() {
    this.spinner.show();
    if (!this.dataValidation()) {
      this.spinner.hide();
      return true;
    } else {
      document.getElementById('error').style.display = 'none';
      this.today = new Date();
      this.today_date = this.datepipe.transform(this.today, 'yyyyMMddHHmmss');
      const credential = {
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins: {}
      }
      credential.Logins['cognito-idp.' + REGION + '.amazonaws.com/' + USER_POOL_ID] = localStorage.getItem('token');
      AWS.config.update({
        region: REGION,
        credentials: new AWS.CognitoIdentityCredentials(credential)
      });
      const s3 = new AWS.S3({
        apiVersion: "2012-10-17",
        params: {
          Bucket: S3_BUCKET
        }
      });
      const uploadFileKey = S3_FOLDER_PATH + this.corporation_no + '_利用者一括登録_' + this.today_date + '.csv';
      this.csvFileName = this.corporation_no + '_利用者一括登録_' + this.today_date + '.csv';
      console_log(uploadFileKey);
      console_log(this.csvFileName);
      const s3Params = {
        // ContentType: 'image/png',
        Body: this.file,
        Bucket: S3_BUCKET,
        Key: uploadFileKey
      };
      let uploadResult = null;
      try {
        uploadResult = await s3.putObject(s3Params).promise();
      } catch (error) { }
      if (uploadResult !== null) {
        this.rest.Create_MembersAll(this.data.uuid, this.corporation_no, this.csvFileName).subscribe(
          (res) => {
            console_log(res);
            if (res.code == 'S000001') {
              this.spinner.hide();
              this.router.navigate(['/contract-user-bulk-regist-comp']);
            } else if (res.code == 'W000801') {
              this.spinner.hide();
              sessionStorage.setItem(res.code, JSON.stringify(res));
              this.router.navigate(['/contract-user-bulk-regist-fail',{code: res.code}]);
            } else if (res.code == 'W000802') {
              this.spinner.hide();
              sessionStorage.setItem(res.code, JSON.stringify(res));
              this.router.navigate(['/contract-user-bulk-regist-fail-list',{code: res.code}]);
            } else {
              this.spinner.hide();
            }
          },
          (err) => {
            console_log(err);
            if (err.error.error.code == 'EF00809') {
              this.spinner.hide();
              sessionStorage.setItem(err.error.error.code, JSON.stringify(err.error.error));
              this.router.navigate(['/contract-user-bulk-regist-fail', {code: err.error.error.code}]);
            } else if (err.error.error.code == 'EF00810') {
              this.spinner.hide();
              sessionStorage.setItem(err.error.error.code, JSON.stringify(err.error.error));
              this.router.navigate(['/contract-user-bulk-regist-fail-list', {code: err.error.error.code}]);
            } else if (err.error.error.code == 'EF00802' || err.error.error.code == 'EF00807' || err.error.error.code == 'C000001' || err.error.error.code == 'C999999') {
              this.spinner.hide();
              this.router.navigate(['/contract-user-bulk-regist-error', {code: err.error.error.code}]);
            } else {
              this.spinner.hide();
              this.router.navigate(['/contract-err', {code: err.error.error.code}]);
            }
          }
        );
      } else {
        this.spinner.hide();
      }
    }
  }
  
  downloadCSV() {
    this.spinner.show();
    window.open(CSV_TEMPLATE);
    this.spinner.hide();
  }

  reset() {
    this.spinner.show();
    if (this.csvfile_name !== undefined)
      document.getElementById("csvfile_name").innerHTML = this.csvfile_name;
    this.file = '';
    this.spinner.hide();
  }

  selectCSV() {
    this.csvfile_name = document.getElementById('csvfile_name').innerHTML;
    const input: HTMLElement = document.getElementById('csvfile');
    input.click();
  }

  uploadCSV(event: Event) {
    const self: BulkRegisterComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      // reader.result.toString();
    }
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.file = element.files[0];
      var filetype = element.files[0].name;
      this.fileType = filetype.slice(filetype.length - 3)
      document.getElementById('csvfile_name').innerHTML = element.files[0].name;
    }
    element.value = '';
    document.getElementById('error').style.display = 'none';
  }
  
}
