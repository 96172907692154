import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public Full_Name = '';
  public Number = '';
  public Use_Status_1 = false;
  public Use_Status_2 = false;
  public Use_Status_3 = false;
  public Use_Status_4 = false;
  public Use_Status = '';
  public Confirm_1 = false;
  public Confirm_0 = false;
  public Confirm_Flg = '';
  public body = [];
  public history_button;
  public History_Flg = '';
  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public pages1;
  public button;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe) {}
 
  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-list';
    this.spinner.show();
    const body = {
      data: {
        user_uuid: this.data.uuid,
        corporation_no: JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
        name: this.Full_Name,
        user_no: this.Number,
        use_status: this.Use_Status,
        confirm_flg: this.Confirm_Flg,
      },
    };
    console_log(body)
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        console_log(res);
        if (res.code == 'S000001') {
          this.pages1 = res.data;
          for (let i = 0; i < this.pages1.length; i++) {
            if (this.pages1[i].pagename == 'contract-user-list-history') {
              console_log('contract-user-list-history');
              if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                this.history_button = true;
              }
              if (this.pages1[i].auth == 'D') {
                this.history_button = false;
              }
            }
            if (this.pages1[i].pagename == 'contract-user-list-detail') {
              console_log('contract-user-list-detail');
              if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                this.button = true;
              }
              if (this.pages1[i].auth == 'D') {
                this.button = false;
              }
            }
          }
        }
      },
    );
    this.rest.Get_ContractsId(this.data.uuid,JSON.parse(localStorage.getItem('loginData')).data.uuid).subscribe((res) => {
        this.History_Flg = res.data.history_flg;
      },
      (err) => {
      }
    );
    if(sessionStorage.getItem('searchData')){
      this.Full_Name = JSON.parse(sessionStorage.getItem('searchData')).data.name;
      this.Number = JSON.parse(sessionStorage.getItem('searchData')).data.user_no;
      this.Use_Status_1 = JSON.parse(sessionStorage.getItem('searchData')).data.use_status1;
      this.Use_Status_2 = JSON.parse(sessionStorage.getItem('searchData')).data.use_status2;
      this.Use_Status_3 = JSON.parse(sessionStorage.getItem('searchData')).data.use_status3;
      this.Use_Status_4 = JSON.parse(sessionStorage.getItem('searchData')).data.use_status4;
      this.Confirm_1 = JSON.parse(sessionStorage.getItem('searchData')).data.confirm_1;
      this.Confirm_0 = JSON.parse(sessionStorage.getItem('searchData')).data.confirm_0;
      this.rest.Get_Members(
        this.data.uuid,
        JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
        JSON.parse(sessionStorage.getItem('searchData')).data.name,
        JSON.parse(sessionStorage.getItem('searchData')).data.user_no,
        JSON.parse(sessionStorage.getItem('searchData')).data.use_status,
        JSON.parse(sessionStorage.getItem('searchData')).data.confirm_flg,
      ).subscribe(
        (res)=>{
          console_log(res)
          this.filteredItems = res.data.PersonInfo;
          if (res.record_count >= 500) {
            document.getElementById('limit500').style.display = 'block';
          } else {
            document.getElementById('limit500').style.display = 'none';
          }
          this.currentIndex = 1;
          this.pageStart = 1;
          this.pages = 6;
  
          this.pageNumber = parseInt(
            '' + this.filteredItems.length / this.pageSize
          );
          if (this.filteredItems.length % this.pageSize != 0) {
            this.pageNumber++;
          }
          if (this.pageNumber < this.pages) {
            this.pages = this.pageNumber;
          }
          this.refreshItems();
          this.spinner.hide();
        },(err)=>{
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        }
      );
    }else{
      this.rest.Get_Members(
        this.data.uuid,
        JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
        this.Full_Name,
        this.Number,
        this.Use_Status,
        this.Confirm_Flg
      ).subscribe(
        (res)=>{
          console_log(res)
          this.filteredItems = res.data.PersonInfo;
          if (res.record_count >= 500) {
            document.getElementById('limit500').style.display = 'block';
          } else {
            document.getElementById('limit500').style.display = 'none';
          }
          this.currentIndex = 1;
          this.pageStart = 1;
          this.pages = 6;
  
          this.pageNumber = parseInt(
            '' + this.filteredItems.length / this.pageSize
          );
          if (this.filteredItems.length % this.pageSize != 0) {
            this.pageNumber++;
          }
          if (this.pageNumber < this.pages) {
            this.pages = this.pageNumber;
          }
          this.refreshItems();
          this.spinner.hide();
        },(err)=>{
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        }
      );
    }
    this.widthChanger('main-header', 700);
    this.widthChanger('content-wrapper', 700);
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }
  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  Search(){
    this.spinner.show();
    // 状態
    if(this.Use_Status_1){
      var status1: string = '1';
    }else{
      this.Confirm_0 = false;
      this.Confirm_1 = false;
      var status1: string = '';
    }
    if(this.Use_Status_2){
      var status2: string = '2';
    }else{
      var status2: string = '';
    }
    if(this.Use_Status_3){
      var status3: string = '3';
    }else{
      var status3: string = '';
    }
    if(this.Use_Status_4){
      var status4: string = '4';
    }else{
      var status4: string = '';
    }
    this.Use_Status = status1 + status2 + status3 + status4;
    // 認証コード
    if(this.Confirm_1){
      var confirm1: string = '1';
    }else{
      var confirm1: string = '';
    }
    if(this.Confirm_0){
      var confirm0: string = '0';
    }else{
      var confirm0: string = '';
    }
    this.Confirm_Flg = confirm0 + confirm1;
    // search response
    const body = {
      data: {
        user_uuid: this.data.uuid,
        corporation_no: JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
        name: this.Full_Name,
        user_no: this.Number,
        use_status: this.Use_Status,
        confirm_flg: this.Confirm_Flg,
      },
    };
    console_log(body);
    // Session responce
    const search = {
      data: {
        name: this.Full_Name,
        user_no: this.Number,
        use_status1: this.Use_Status_1,
        use_status2: this.Use_Status_2,
        use_status3: this.Use_Status_3,
        use_status4: this.Use_Status_4,
        confirm_1: this.Confirm_1,
        confirm_0: this.Confirm_0,
        use_status: this.Use_Status,
        confirm_flg: this.Confirm_Flg,
      },
    };
    sessionStorage.setItem('searchData', JSON.stringify(search));
    this.rest.Get_Members(
      this.data.uuid,
      JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
      this.Full_Name,
      this.Number,
      this.Use_Status,
      this.Confirm_Flg
    ).subscribe(
      (res)=>{
        console_log(res)
        this.filteredItems = res.data.PersonInfo;
        if (res.record_count >= 500) {
          document.getElementById('limit500').style.display = 'block';
        } else {
          document.getElementById('limit500').style.display = 'none';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },(err)=>{
        this.spinner.hide();
        console_log(err);
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    )
  }
  details(user_code){
    this.router.navigate(['/contract-user-list-detail',{user_code: user_code}]);
  }
  userhistory(casio_id, name){
    this.router.navigate(['/contract-user-list-history',{casio_id: casio_id, name: name}]);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
}
