<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}</h3>
  
  <section class="content" id="userhistory">
    <div class="card-body">
      <div class="row">
        <p class="left-border">{{ 'REGISTRATION_LIST.USAGE_HISTORY' | translate }}</p>
      </div>
      <!-- 1 -->
      <div class="row" style="margin-top:20px;">
        <div class="column-check1">
          <div class="text-label">
            <label class="ftext"> {{ 'REGISTRATION_LIST.CONTRACTOR_NAME' | translate }}</label>
          </div>
        </div>
        <div class="column-check1">
          <div class="text-label">
            <label class="ftext">{{Contractor_Name}}</label>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="row" style="margin-top:20px;">
        <div class="column-check1">
          <div class="text-label">
            <label class="ftext"> {{ 'REGISTRATION_LIST.NUMBER_OF_USES_THIS_MONTH' | translate }}</label>
          </div>
        </div>
        <div class="column-check1">
          <div class="text-label">
            <label class="ftext">{{Use_Count}}{{ 'COMMON.TIMES' | translate }}</label>
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="row search-btn">
        <div class="input-group datetime-cls">
          <!-- <ngx-datepicker [(ngModel)]="Start_Date" [options]="options" #date1>
          </ngx-datepicker> -->
        <dp-date-picker style="height: 40px;" [(ngModel)]="Start_Date" [config]="config" mode="month" (onGoToCurrent)="GoToCurrent(Today)"></dp-date-picker>
          <img class="icon date-icon" src="assets/icons/date.svg"/>
        </div>
        <div class="column-check btn-search-top">
          <a target="_blank" class="btn-search" (click)="Search()"
            >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
            <img
              style="margin-left: 20px; right: 0%; margin-top: -3px"
              src="assets/icons/srch.svg"
            />
          </a>
        </div>
      </div>
      <label class="limit-500" id="WG00101">
        {{'ERROR.WG00101' | translate}}
      </label>
      <!-- 4 -->
      <div class="row select-radio-top">
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                checked
                id="r1"
                name="customRadio" (click)="selectedRadios(10)"
              />
              <label for="r1" class="custom-control-label ftext">{{
                'COMMON.CASE_10' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r2"
                name="customRadio" (click)="selectedRadios(20)"
              />
              <label for="r2" class="custom-control-label ftext">{{
                'COMMON.CASE_20' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r3"
                name="customRadio" (click)="selectedRadios(50)"
              />
              <label for="r3" class="custom-control-label ftext">{{
                'COMMON.CASE_50' | translate
              }}</label>
            </div>
          </div>
        </div>
        <div class="column-check">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input custom-control-input-danger custom-control-input-outline"
                type="radio"
                id="r4"
                name="customRadio" (click)="selectedRadios(100)"
              />
              <label for="r4" class="custom-control-label ftext">{{
                'COMMON.CASE_100' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>

    <!-- 5 -->
    <section class="content">
      <div class="container-fluid" style="color: black;">
          <div class="row table-width">
            <table id="example3" class="table table-bordered" style="background-color: white;">
              <thead>
                <tr>
                  <th class="ftext">{{ 'REGISTRATION_LIST.USED_DATE' | translate }}</th>
                  <th class="ftext">{{ 'REGISTRATION_LIST.USED_TIME' | translate }}</th>
                  <th class="ftext">{{ 'REGISTRATION_LIST.USED_STORE' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of items">
                  <td style="text-align: center;">{{list.use_date}}</td>
                  <td style="text-align: center;">{{list.use_start}} - {{list.use_end}}</td>
                  <td>{{list.club_name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <label class="pages">{{ 'COMMON.PAGE' | translate }} {{ currentIndex }}/{{ pageNumber }}</label>
          <div class="page-point">
            <span
              [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
              (click)="prevPage()"
              class="prev"
              >&laquo;</span
            >
            <span
              *ngFor="let page of pagesIndex"
              [ngClass]="{ tod: currentIndex == page }"
              class="dot"
              (click)="setPage(page)"
            ></span>
            <span
              [ngClass]="{
                disabled: currentIndex == pageNumber || pageNumber == 0
              }"
              (click)="nextPage()"
              class="next"
            >&raquo;
            </span>
          </div>
        </div>
    </section>
    
    <!-- BUTTON -->
    <div class="text-center back-button">
      <div class="col-12">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.BACK_TO_REGISTRATION_LIST' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
    </div>
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > 
    {{ 'COMMON.LOADING' | translate }}</p>>
</ngx-spinner>