import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  public email;
  public logoUrl;

  submitted = false;
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'register-page');
      this.registerForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
  }

  onChange() {
    document.getElementById('errormsg').innerHTML = "";
  }

  next() {
    if (this.registerForm.valid) {
      if(this.email == '') {
        this.translate.get('RESET_PASSWORD.PASSWORD_RESET_FAILED').subscribe(
          (res) => (document.getElementById('errormsg').innerHTML = res)
        );
        return;
      }
      this.appService.forgotPassword(this.email).then(res => {
        this.router.navigate(['/contract-change-pw', {email: this.email}]);
      });
    } else {
      this.translate.get('RESET_PASSWORD.PASSWORD_RESET_FAILED').subscribe(
        (res) => (document.getElementById('errormsg').innerHTML = res)
      );
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
  }
}
