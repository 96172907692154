<div class="main-padding">
  <!-- Section 1 -->
  <section class="content" id="info">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <p class="subtitleText">{{ 'HOME2.NOTICE' | translate }}</p>
      </div>
      <div class="row">
        <textarea id="txtarea1" name="w3review" rows="4">2021/03/08　10:00よりメンテナンスの予定。
        </textarea>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <button class="btn-pinkmini"><img src="assets/icons/book.svg"/> {{ 'HOME2.MANUAL' | translate }}</button>
      </div>
      <div class="row">
        <p class="subtitleText" style="margin-top: 22px;">{{ 'HOME2.CONTRACT_DETAIL' | translate }}</p>
      </div>
      <div class="row">
          <textarea id="txtarea2" name="w3review" rows="4">
            契約形態 
            ◯◯◯◯◯◯◯◯◯◯◯◯◯◯ 
            
            ご利用人数 
            ◯◯◯人
          </textarea>
      </div>
    </div>
  </section>
</div>