import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { S3_URL } from 'src/environments/environment';

@Component({
  selector: 'app-bulk-register',
  templateUrl: './bulk-regist-fail.component.html',
  styleUrls: ['./bulk-regist-fail.component.scss'],
})
export class BulkRegisterFailComponent implements OnInit {
  public csv_file_url = '';
  public code = '';

  constructor(private router: Router,
    public data: DataProvider,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public translate: TranslateService,) {}
  
  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-bulk-regist';
    this.code = this.route.snapshot.paramMap.get('code');
    if (this.code == 'EF00809' || this.code == 'EF00810') {
      this.translate.get('REGISTER_ALL_USER_FAIL.FAILED_TO_BATCH_USER_REGISTRATION').subscribe(
        (res) => {
          document.getElementById('title').innerHTML = res;
        }
      );
    }
    this.csv_file_url = JSON.parse(sessionStorage.getItem(this.code)).data.csv_file.replace(S3_URL, '');
    sessionStorage.clear();
  }

  downloadCSV() {
    this.spinner.show();
    window.open(this.csv_file_url);
    this.spinner.hide();
  }
  back(){
    this.router.navigate(['/contract-user-bulk-regist']);
  }
  
  changeuserinfo(){
    this.router.navigate(['/contract-user-list']);
  }
}
