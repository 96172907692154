<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}</h3>
  <section class="content" id="changeuserinfo">
    <div class="card-body">
      <p class="left-border">{{ 'REGISTRATION_LIST.CHANGE_OF_USERINFO' | translate }}</p>
      <p class="card-title">{{ 'REGISTRATION_LIST.ENTER_CHANGED_PART_MSG' | translate }}</p>
    </div>
     <!-- 1 -->
     <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-sm-3 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title" style="margin-top: 12px;"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-9 col-md-9 col-xl-9 col-inpt">
          <div class="col-12 display">
            <div class="col-12 col-md-1 col-xl-1 display">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
            </div>
            <div class="col-12 col-md-5 col-xl-5 display">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'COMMON_WORDS.SURNAME_PLACEHOLDER' | translate }}"
                name="User_Last_Name" [(ngModel)]="User_Last_Name"
              />
            </div>
            <div class="col-12 col-md-5 col-xl-5 display">
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'COMMON_WORDS.NAME_PLACEHOLDER' | translate }}"
                name="User_First_Name" [(ngModel)]="User_First_Name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title" style="margin-top: 12px;"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
          <div class="col-12 display">
            <div class="col-12 col-md-1 col-xl-1 display">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
            </div>
            <div class="col-12 col-md-10 col-xl-10 display">
              <input
                type="email"
                class="form-control"
                placeholder="sample_mail@abcd.co.jp"
                name="Mail_Address" [(ngModel)]="Mail_Address"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title" style="margin-top: 12px;" *ngIf="Type == 'A'"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
          <h3 class="card-title" style="margin-top: 12px;" *ngIf="Type == 'B'"> {{ 'REGISTER_USER.ID_DEST' | translate }}</h3>
          <h3 class="card-title" style="margin-top: 12px;" *ngIf="Type == 'C'"> {{ 'REGISTER_USER.SYMBOL_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
          <div class="col-12 display">
            <div class="col-12 col-md-1 col-xl-1 display">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
            </div>
            <div class="col-12 col-md-10 col-xl-10 display">
              <input
                type="text"
                class="form-control"
                placeholder="0000000000"
                name="User_Id_1"
                [(ngModel)]="User_Id_1"
                maxlength="30"
                onkeypress="return (event.charCode != 45)"
                  (change)="CharCode()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- 番号 -->
     <div class="card-body" *ngIf="Type == 'C'">
      <div class="row detail1">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title" style="margin-top: 12px;"> {{ 'REGISTER_USER.NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
          <div class="col-12 display">
            <div class="col-12 col-md-1 col-xl-1 display">
              <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
            </div>
            <div class="col-12 col-md-10 col-xl-10 display">
              <input
                type="text"
                class="form-control"
                placeholder="0000000000"
                name="User_Id_2" 
                [(ngModel)]="User_Id_2"
                maxlength="30"
                onkeypress="return (event.charCode != 45)"
                  (change)="CharCode()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
     <!-- 枝番 -->
     <div class="card-body" *ngIf="Type == 'C'">
      <div class="row detail1">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title" style="margin-top: 12px;"> {{ 'REGISTER_USER.BRANCH_NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
          <div class="col-12 display">
            <div class="col-12 col-md-1 col-xl-1 display">
            </div>
            <div class="col-12 col-md-10 col-xl-10 display">
              <input
                type="text"
                class="form-control"
                placeholder="0000000000"
                name="User_Id_3"
                [(ngModel)]="User_Id_3"
                maxlength="30"
                onkeypress="return (event.charCode != 45)"
                (change)="CharCode()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body">
      <div class="row detail1">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USAGE_FORM' | translate }}</h3>
        </div>
        <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
          <div class="col-12 display">
            <div class="col-12 col-md-1 col-xl-1 display">
            </div>
            <div class="col-12 col-md-10 col-xl-10 display">
              <div class="col-12 col-md-5 col-xl-5 display">
                <h3 class="content" *ngIf="Use_Status == '1'">
                  {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
                </h3>
                <h3 class="content" *ngIf="Use_Status == '2'">
                  {{ 'REGISTRATION_LIST.ONGOING' | translate }}
                </h3>
                <h3 class="content" *ngIf="Use_Status == '3'">
                  {{ 'REGISTRATION_LIST.RECESS' | translate }}
                </h3>
                <h3 class="content" *ngIf="Use_Status == '4'">
                  {{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BUTTON -->
    <div class="text-center">
      <div class="col-12">
        <div class="text1 text-center">
          <p class="error" id="errormsgsett"></p>
        </div>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="back(1)" class="btn-grey">
            {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="input()" class="btn-pink">
            {{ 'BUTTON_TYPES.REGISTER' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>

  <section class="content" id="changeuserinfoconfirm" style="display: none;">
    <div class="card-body">
      <p class="left-border">{{ 'REGISTRATION_LIST.CONFIRM_USER_INFO_CHANGE' | translate }}</p>
      <p class="card-title">{{ 'REGISTRATION_LIST.CONFIRM_CHANGED_PART_MSG' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title">{{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="card-title display">{{ User_Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ User_First_Name }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content">{{Mail_Address}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"*ngIf="Type == 'A'"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'B'"> {{ 'REGISTER_USER.ID_DEST' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'C'"> {{ 'REGISTER_USER.SYMBOL_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content"> {{User_Id_1}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 番号 -->
    <div class="card-body" *ngIf="Type == 'C'">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'REGISTER_USER.NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content"> {{User_Id_2}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 枝番 -->
    <div class="card-body" *ngIf="Type == 'C'">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'REGISTER_USER.BRANCH_NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content"> {{User_Id_3}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USAGE_FORM' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content" *ngIf="Use_Status == '1'">
                {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '2'">
                {{ 'REGISTRATION_LIST.ONGOING' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '3'">
                {{ 'REGISTRATION_LIST.RECESS' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '4'">
                {{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BUTTON -->
    <div class="text-center">
      <p class="card-result">{{ 'REGISTRATION_LIST.REGISTER_USER_MSG' | translate }}</p>
    </div>
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back(2)" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.NO' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="finish()" class="btn-pink">
            <i></i> {{ 'BUTTON_TYPES.YES' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>

  <section class="content" id="changeuserinfofinish" style="display: none;">
    <div class="title_margintop">
      <h3 style="text-align: center; color: black;">{{ 'REGISTRATION_LIST.COMPLETED_USER_INFO_CHANGE1' | translate }}
      <br>{{ 'REGISTRATION_LIST.COMPLETED_USER_INFO_CHANGE2' | translate }}</h3>
    </div>
    <div class="text-center" style="margin-top: 40px;">
      <a (click)="list()" class="btn-grey1">
        <i></i> {{ 'BUTTON_TYPES.BACK_TO_USER_DETAIL' | translate }}
      </a>
    </div>
    <!-- BUTTON end -->
  </section>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#f77fb1" type="square-jelly-box" [fullScreen]="true">
  <p style="color:#f77fb1"> 
    {{'COMMON.LOADING' | translate }}</p>
</ngx-spinner>