import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-authmail',
  templateUrl: './authmail.component.html',
  styleUrls: ['./authmail.component.scss'],
})
export class BulkAuthmailComponent implements OnInit {
  public use_status = '1';
  public customerName = '';
  public pages1;
  public contract_user_list_detail;
  public send_data = [];
  
  tableItem = [];
  constructor(private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-bulk-authmail';
    this.spinner.show();
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        console_log(res);
        if (res.code == 'S000001') {
          this.pages1 = res.data;
          for (let i = 0; i < this.pages1.length; i++) {
            if (this.pages1[i].pagename == 'contract-user-list-detail') {
              console_log('contract-user-list-detail');
              if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                this.contract_user_list_detail = true;
              }
              if (this.pages1[i].auth == 'D') {
                this.contract_user_list_detail = false;
              }
            }
          }
          this.spinner.hide();
        }
      },
    );
    this.rest.Get_Members(
      this.data.uuid,
      JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
      '',
      '',
      this.use_status,
      ''
    ).subscribe(
      (res)=>{
        console_log(res);
        this.tableItem = res.data.PersonInfo;
        this.spinner.hide();
      },(err)=>{
        console_log(err);
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        this.spinner.hide();
      }
    );
  }
  info(){
    // document.getElementById('transmission').style.display='none';
    document.getElementById('exampleModalLong').style.display='block';
  }
  details(user_code){
    this.router.navigate(['/contract-user-list-detail',{user_code: user_code}]);
  }
  btnno(){
    document.getElementById('exampleModalLong').style.display='none';
    document.getElementById('transmission').style.display='block';
  }
  btnyes(){
    this.spinner.show();
    for(let i = 0; i<this.tableItem.length;i++){
      this.send_data.push({
        user_code: this.tableItem[i].user_code,
        last_name: this.tableItem[i].last_name,
        first_name:this.tableItem[i].first_name,
        mail_address: this.tableItem[i].mail_address
      });
    }
    this.rest.Send_AuthMailAll(
      this.data.uuid,
      this.send_data
    ).subscribe(
      (res)=>{
        console_log(res);
        this.spinner.hide();
        if(res.code === "WF00701"){
          sessionStorage.setItem('WF00701', JSON.stringify(res));
          this.router.navigate(['/contract-bulk-authmail-error']);
        }else if(res.code === 'S000001'){    
          this.router.navigate(['/contract-bulk-authmail-comp']);
        }
      },(err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
}
