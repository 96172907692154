import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEn from '@angular/common/locales/en';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient, HttpHandler } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxLoadersCssModule } from 'ngx-loaders-css';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';

import { HomeComponent } from './views/1-contract-home/home.component';
import { CorpReferenceComponent } from './views/2-contract-corp/corp-reference.component';
import { MailReferenceComponent } from './views/3-contract-mail/reference/mail-reference.component';
import { MailEditComponent } from './views/3-contract-mail/edit/mail-edit.component';
import { MailSendedComponent } from './views/3-contract-mail/sended/mail-sended.component';
import { RegisterComponent } from './views/4-contract-user-register/register/register.component';
import { RegisterCompleteComponent } from './views/4-contract-user-register/complete/complete.component';
import { AuthmailConfigureComponent } from './views/4-contract-user-register/authmail-configure/configure.component';
import { BulkRegisterComponent } from './views/5-user-bulk-register/bulk-register.component';
import { BulkRegisterFailComponent } from './views/5-user-bulk-register/bulk-regist-fail/bulk-regist-fail.component';
import { BulkRegisterFailListComponent } from './views/5-user-bulk-register/bulk-regist-fail-list/bulk-regist-fail-list.component';
import { BulkRegisterErrorComponent } from './views/5-user-bulk-register/bulk-regist-error/bulk-regist-error.component';
import { ListComponent } from './views/6-contract-user-list/list/list.component';
import { ListDetailComponent } from './views/6-contract-user-list/detail/detail.component';
import { ListEditComponent } from './views/6-contract-user-list/edit/edit.component';
import { ListDeleteComponent } from './views/6-contract-user-list/delete/delete.component';
import { ListHistoryComponent } from './views/6-contract-user-list/history/history.component';

// import { RegisterComponent } from './views/4-contract-user-register/register/register.component';

import { MainhomeComponent } from './views/main/main.component';
import { AppButtonComponent } from './components/app-button/app-button.component';

import { WarningComponent } from './views/warning/warning.component';

import { BulkAuthmailComponent } from './views/7-contract-bulk-authmail/authmail/authmail.component';
import { BulkAuthmailCompleteComponent } from './views/7-contract-bulk-authmail/authmail-complete/authmail-complete.component';
import { BulkAuthmailErrorComponent } from './views/7-contract-bulk-authmail/authmail-error/error.component';


import { DataProvider } from '../providers/data/data';
import { CallapiService } from '../providers/rest/callapi.service';
import { MailCompleteComponent } from './pages/mail-comp/mail-comp.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ErrorComponent } from './pages/error/error.component';
import { ViewsErrorComponent } from './views/error/error.component';
import { DatepickerModule } from 'ng2-datepicker';
import { MailErrorComponent } from './pages/mail-err/mail-err.component';
import { CorpEditComponent } from './views/2-contract-corp/corp-edit/corp-edit.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { UnpaidPaymentComponent } from './views/8-contract-unpaid-payment/unpaid-payment.component';
import { UnpaidPaymentConfirmComponent } from './views/8-contract-unpaid-payment/unpaid-payment-conf/unpaid-payment-conf.component';
import { UnpaidPaymentCompleteComponent } from './views/8-contract-unpaid-payment/unpaid-payment-comp/unpaid-payment-comp.component';
import { BulkRegisterCompComponent } from './views/5-user-bulk-register/bulk-regist-comp/bulk-regist-comp.component';
import { UnpaidPaymentErrorComponent } from './views/8-contract-unpaid-payment/unpaid-payment-err/unpaid-payment-err.component';
import { UnpaidPaymentCancelComponent } from './views/8-contract-unpaid-payment/unpaid-payment-cancel/unpaid-payment-cancel.component';
registerLocaleData(localeEn, 'en-EN');
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + new Date().getTime());
}
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    ProfileComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    MainhomeComponent,
    HomeComponent,
    CorpReferenceComponent,
    MailReferenceComponent,
    MailEditComponent,
    MailSendedComponent,
    MailCompleteComponent,
    MailErrorComponent,
    RegisterComponent,
    RegisterCompleteComponent,
    BulkRegisterComponent,
    BulkRegisterFailComponent,
    BulkRegisterFailListComponent,
    BulkRegisterErrorComponent,
    BulkRegisterCompComponent,
    ListComponent,
    ListDetailComponent,
    ListEditComponent,
    ListDeleteComponent,
    ListHistoryComponent,
    BulkAuthmailComponent,
    BulkAuthmailCompleteComponent,
    BulkAuthmailErrorComponent,
    AppButtonComponent,
    WarningComponent,
    AuthmailConfigureComponent,
    ErrorComponent,
    ViewsErrorComponent,
    CorpEditComponent,
    UnpaidPaymentComponent,
    UnpaidPaymentConfirmComponent,
    UnpaidPaymentCompleteComponent,
    UnpaidPaymentErrorComponent,
    UnpaidPaymentCancelComponent
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    NgxLoadersCssModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModule,
    DatepickerModule,
    DpDatePickerModule,
  ],
  providers: [DataProvider, CallapiService,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
