<div style="position: absolute;top: 0;left: 0;width: 100%;">
  <nav class="navbar navbar-expand navbar-white navbar-light" style="margin-left: 0px;">
    <a [routerLink]="['/login']">
    <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
      /></a>
  </nav>
</div>
<div class="login-box">
  <div class="login-logo">
    <i class='blackuser'></i>
    <p style="margin-top: -6px;" class="title-login">{{ 'LOGIN.TITLE' | translate }}</p>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="input-group mb-3" style="margin-top: 40px;">
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="lock ls"></span>
        </div>
      </div>
      <input
        formControlName="email"
        type="email"
        class="form-control"
        placeholder="{{ 'LOGIN.MAIL_ADDRESS_PLACEHOLDER' | translate }}"
        [(ngModel)]="email"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="input-group mb-3" style="margin-top: -6px;">
      <div class="input-group-append">
        <div class="input-group-text">
          <span class="key ls"></span>
        </div>
      </div>
      <input
        formControlName="password"
        type="password"
        class="form-control"
        placeholder="{{ 'LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
        [(ngModel)]="password"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="text-center">
      <div class="text1 text-center" style="height: 34px">
        <p id="errormsglogin"></p>
      </div>
      <div class="display" style="margin-top: 6px">
        <a (click)="login()" class="btn-log">
          {{ 'LOGIN.LOGIN_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </form>
  <p class="text text-center">
    <a [routerLink]="['/contract-forgot-pw']" class="tbtext"><span class="fas fa-chevron-right"></span>
      {{ 'LOGIN.RESET_PASSWORD_LINK' | translate }}</a>
  </p>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > 
    {{ 'COMMON.LOADING' | translate }}</p>>
</ngx-spinner>
