<section>
  <div class="main-padding">
  <!-- Section 1 -->
  <section class="content" id="info">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <p class="subtitleText">{{ 'HOME.HOME_TITLE_1' | translate }}</p>
      </div>
      <div class="row notice-home">
        <p class="notice" *ngFor="let list of Notice_Data">{{list.date}}&nbsp;&nbsp;{{list.content}}</p>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <a class="btn-pinkmini" (click)="ManualPDF()"><img src="assets/icons/book.svg"/> {{ 'HOME2.MANUAL' | translate }}</a>
      </div>
      <div class="row">
        <p class="subtitleText" style="margin-top: 22px;">{{ 'HOME.HOME_TITLE_2' | translate }}</p>
      </div>
      <div class="row notice-home" style="height: 168px;">
        <p class="detail1">{{ 'HOME.HOME_TEXT_1' | translate }}</p>
        <p class="detail1" *ngIf="Corporation_Form == '1'">
          {{ 'COMPANY_INFO.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
        </p>
        <p class="detail1" *ngIf="Corporation_Form == '2'">
          {{ 'COMPANY_INFO.HEALTH_INSURANCE' | translate }}
        </p>
        <p class="detail1">{{ 'HOME.HOME_TEXT_2' | translate }}</p>
        <p class="detail1">{{Users}}人</p>
      </div>
    </div>
  </section>
</div>
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>