export const environment = {
  production: false
};

export const IDENTITY_POOL_ID = 'ap-northeast-2:e3c70784-f4a7-4291-bcfa-85abdda7be83';
export const USER_POOL_ID = 'ap-northeast-2_DrkDsgLPi';
export const CLIENT_ID = '14cns067b3v1rth4r13e1m123o';
export const REGION = 'ap-northeast-2';
export const ACCESS_KEY_ID = 'AKIAQWW63P2YSYIXQOGI';
export const SECRET_ACCESS_KEY = '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';
export const MANUAL_PDF_URL = 'https://test-uconsole.fit365-service.jp/pdf/unconsole_manual.pdf';

export const API_URL = 'https://n2zmg1n1ml.execute-api.ap-northeast-2.amazonaws.com/test';
export const API_KEY = 'V2wavfRe05m7YZD2h96haW9e4rnzI76zIWXNG+Hp';
export const CSV_TEMPLATE = 'https://test-uconsole.fit365-service.jp/csv/user-regist/%E5%88%A9%E7%94%A8%E8%80%85%E4%B8%80%E6%8B%AC%E7%99%BB%E9%8C%B2%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%83%E3%83%88.csv';
export const S3_BUCKET = 'test-uconsole.fit365-service.jp';
export const S3_FOLDER_PATH = 'csv/user-regist/upload/';
export const S3_URL = 's3.ap-northeast-2.amazonaws.com/';