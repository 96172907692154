<div class="main-padding">
  <h3 class="header-bottom">{{ 'ALL_VERIFICATION_CODE_SEND.TITLE_TEXT' | translate }}</h3>
  <section class="content" id="transmission">
    <div class="card-body">
      <p class="left-border">{{ 'ALL_VERIFICATION_CODE_SEND.ALL_VERIFICATION_CODE_SEND' | translate }}</p>
      <p class="card-title">{{ 'ALL_VERIFICATION_CODE_SEND.SEND_VERIFICATION_CODE' | translate }}</p>
    </div>
    <section class="content padding_left"> 
      <div class="container-fluid" style="color: black;">
        <div class="row table-width">
          <table id="example2" class="table table-bordered" style="background-color: white;min-width: 600px">
            <thead>
              <tr>
                <th>{{ 'REGISTRATION_LIST.CONTRACTOR_NAME' | translate }}</th>
                <th>{{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</th>
                <th  *ngIf="contract_user_list_detail">{{ 'COMMON_WORDS.USER_DETAIL' | translate }}</th>
                <th>{{ 'COMMON_WORDS.NUMBER' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of tableItem">
                <td class="tdata-1">{{list.last_name}} {{list.first_name}}</td>
                <td class="tdata-2">{{list.mail_address}}</td>
                <td class="tdata-3" *ngIf="contract_user_list_detail">
                  <button (click)="details(list.user_code)" type="button" class="btn-pinktbl">{{ 'COMMON_WORDS.USER_DETAIL' | translate }}</button>
                </td>
                <td class="tdata-4">{{list.user_no}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <!-- BUTTON -->
    <div class="text-center">
      <div class="col-12 button-margin-top ">
        <div class="display">
          <a (click)="info()" class="btn-log" data-toggle="modal" data-target="#exampleModalLong">
            <i></i> {{ 'BUTTON_TYPES.SEND' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>
</div>
<!-- Popup -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="title_margintop2">
            <h3 class="header-bottom"  style="color: black; text-align: center;">{{ 'ALL_VERIFICATION_CODE_SEND.ALL_VERIFICATION_CODE_SEND' | translate }}</h3>
         </div>
        <div class="text-center">
          <div class="col-12 two-btn">
            <div class="display">
              <div style="width: 225px;">
                <p class="card-result">{{ 'ALL_VERIFICATION_CODE_SEND.SEND_VERIFCD_MULTI1' | translate }}<br>{{ 'ALL_VERIFICATION_CODE_SEND.SEND_VERIFCD_MULTI2' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="col-12 two-btn">
            <div class="display">
              <a (click)="btnno()" data-dismiss="modal" data-target="exampleModalLong" class="btn-grey">{{ 'BUTTON_TYPES.NO' | translate }}
              </a>
            </div>
            <div class="display">
              <a (click)="btnyes()" data-dismiss="modal" data-target="exampleModalLong" class="btn-pink">{{ 'BUTTON_TYPES.YES' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
