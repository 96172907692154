<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTER_USER' | translate }}</h3>

  <!-- Input -->
  <section class="content" id="input">
    <div class="card-body">
      <p class="left-border">{{ 'REGISTER_USER.REGISTER_USER' | translate }}</p>
      <p class="card-title">{{ 'REGISTER_USER.PLEASE_CONFIRM_CHANGE_POINTS' | translate }}</p>
    </div>
    <!-- 1 -->
      <div class="card-body">
        <div class="row detail1">
          <div class="col-12 col-sm-3 col-md-3 col-xl-3 col-desc">
            <h3 class="card-title" style="margin-top: 12px;"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
          </div>
          <div class="col-12 col-sm-9 col-md-9 col-xl-9 col-inpt">
            <div class="col-12 display">
              <div class="col-12 col-md-1 col-xl-1 display">
                <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
              </div>
              <div class="col-12 col-md-5 col-xl-5 display">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ 'COMMON_WORDS.SURNAME_PLACEHOLDER' | translate }}"
                  name="User_Last_Name" [(ngModel)]="User_Last_Name"
                />
              </div>
              <div class="col-12 col-md-5 col-xl-5 display">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ 'COMMON_WORDS.NAME_PLACEHOLDER' | translate }}"
                  name="User_First_Name" [(ngModel)]="User_First_Name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="card-body">
        <div class="row detail1">
          <div class="col-12 col-md-3 col-xl-3 col-desc">
            <h3 class="card-title" style="margin-top: 12px;"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
          </div>
          <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
            <div class="col-12 display">
              <div class="col-12 col-md-1 col-xl-1 display">
                <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
              </div>
              <div class="col-12 col-md-10 col-xl-10 display">
                <input
                  type="email"
                  class="form-control"
                  placeholder="sample_mail@abcd.co.jp"
                  name="Mail_Address" [(ngModel)]="Mail_Address"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 3 -->
      <div class="card-body">
        <div class="row detail1">
          <div class="col-12 col-md-3 col-xl-3 col-desc">
            <h3 class="card-title" style="margin-top: 12px;"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
          </div>
          <div class="col-12 col-md-9 col-xl-9 col-inpt mail-address">
            <div class="col-12 display">
              <div class="col-12 col-md-1 col-xl-1 display">
                <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
              </div>
              <div class="col-12 col-md-10 col-xl-10 display">
                <input
                  type="text"
                  class="form-control"
                  placeholder="0000000000"
                  name="User_Id_1" 
                  [(ngModel)]="User_Id_1"
                  maxlength="30"
                  onkeypress="return (event.charCode != 45)"
                  (change)="CharCode()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BUTTON -->
      <div class="text-center">
        <div class="col-12">
          <div class="text1 text-center">
            <p class="error" id="errormsgsett"></p>
          </div>
        </div>
        <div class="col-12">
          <div class="display">
            <a (click)="input()" class="btn-log">
              <i></i> {{ 'BUTTON_TYPES.REGISTER' | translate }}
            </a>
          </div>
        </div>
      </div>
      <!-- BUTTON end -->
  </section>


  <!-- Confirm -->
  <section class="content" id="confirm" style="display: none;">
    <div class="card-body">
      <p class="left-border">{{ 'REGISTER_USER.REGISTER_USER_NEXT' | translate }}</p>
      <p class="card-title">{{ 'REGISTER_USER.PLEASE_CONFIRM_CHANGE_POINTS_NEXT' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5 col-inpt">
          <div class="col-12 display">
              <h3 class="card-title display">{{ User_Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ User_First_Name }}
              </h3>
          </div>
        </div>
      </div>
    </div>

    <!-- 2 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5 col-inpt">
          <div class="col-12 display">
              <h3 class="content">{{Mail_Address}}</h3>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-md-3 col-xl-3 col-desc">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5 col-inpt">
          <div class="col-12 display">
              <h3 class="content"> {{User_Id_1}}</h3>
            </div>
        </div>
      </div>
    </div>

    <!-- BUTTON -->
    <div class="card-body">
      <p class="card-result" >{{ 'REGISTRATION_LIST.REGISTER_USER_MSG' | translate }}</p>
      <div class="text-center" style="margin-top: 50px;">
        <div class="two-btn">
          <div class="display">
            <a (click)="back()" class="btn-grey">
              {{ 'BUTTON_TYPES.NO' | translate }}
            </a>
          </div>
          <div class="display">
            <a (click)="finish()" class="btn-pink">
              {{ 'BUTTON_TYPES.YES' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
</section>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#f77fb1" type="square-jelly-box" [fullScreen]="true">
  <p style="color: #f77fb1"> 
    {{'COMMON.LOADING' | translate }}</p>
</ngx-spinner>
