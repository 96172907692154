<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}</h3>
  <section class="content" id="userdetail" style="display: block;">
    <div class="card-body">
      <p class="left-border">{{ 'COMMON_WORDS.USER_DETAIL' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row" style="justify-content: flex-end;" *ngIf="Use_Status == '1'">
        <button type="button" (click)="deleteUserInfo()" class="btn-pinkmini btn-pinklng">{{ 'BUTTON_TYPES.USER_INFO_DELETE' | translate }}</button>
      </div>
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-11 display">
            <div class="col-10 col-md-12 col-xl-12 display">
               <h3 class="card-title display">{{ User_Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ User_First_Name }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content">{{Mail_Address}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"*ngIf="Type == 'A'"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'B'"> {{ 'REGISTER_USER.ID_DEST' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'C'">  {{ 'COMMON_WORDS.NUMBER' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content"> {{Number}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USAGE_FORM' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-11 display">
            <div class="col-5 col-md-5 col-xl-5 display">
              <h3 class="content" *ngIf="Use_Status == '1'">
                {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '2'">
                {{ 'REGISTRATION_LIST.ONGOING' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '3'">
                {{ 'REGISTRATION_LIST.RECESS' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '4'">
                {{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}
              </h3>
            </div>
            <div class="col-6 col-md-7 col-xl-7 display"  *ngIf="Use_Status == '1' && send_button && detail">
                <button (click)="SendMailDetial()" type="button" class="btn-pinkmini btn-pinklng">{{ 'BUTTON_TYPES.VERIFICATION_CODE_SEND' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="return()" class="btn-grey">
            {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
        <div class="display" *ngIf="button && detail">
          <a (click)="changeUserInfo()" class="btn-pink">
            {{ 'BUTTON_TYPES.USER_INFO_CHANGE' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>

  <section class="content" id="sendverification" style="display: none;">
    <div class="card-body">
      <p class="left-border">{{ 'REGISTRATION_LIST.VERIFICATION_CODE_SEND' | translate }}</p>
      <p class="card-title">{{ 'REGISTRATION_LIST.CHK_MISTAKES_CLICKYES_MSG' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-11 display">
            <div class="col-10 col-md-12 col-xl-12 display">
              <h3 class="card-title display">{{ User_Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ User_First_Name }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content">{{Mail_Address}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"*ngIf="Type == 'A'"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'B'"> {{ 'REGISTER_USER.ID_DEST' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'C'">  {{ 'COMMON_WORDS.NUMBER' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content"> {{Number}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3 col-dest">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USAGE_FORM' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5 col-dest1">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content" *ngIf="Use_Status == '1'">
                {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '2'">
                {{ 'REGISTRATION_LIST.ONGOING' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '3'">
                {{ 'REGISTRATION_LIST.RECESS' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '4'">
                {{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center">
      <p class="card-result">{{ 'REGISTRATION_LIST.SEND_AUTH_EMAIL_MSG' | translate }}</p>
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.NO' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="SendMail()" class="btn-pink">
            <i></i> {{ 'BUTTON_TYPES.YES' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>

  <section class="content" id="sendverificationok" style="display: none;">
    <div class="title_margintop">
      <h3 style="color: black; text-align: center;">{{ 'REGISTRATION_LIST.SENT_VERIFICATION_CODE' | translate }}</h3>
    </div>
    <div class="text-center">
      <p class="card-result">{{ 'EXAMINATION_CONFIRM.TITLE_CENTER_TEXT' | translate }}</p>
    </div>
  </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#f77fb1 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #f77fb1 " > 
    {{ 'COMMON.LOADING' | translate }}</p>>
</ngx-spinner>