import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/utils/services/app.service';
import { environment } from 'src/environments/environment';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-mail-err',
  templateUrl: './mail-err.component.html',
  styleUrls: ['./mail-err.component.scss'],
})
export class MailErrorComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public email;
  public logoUrl;
  public errorCode;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    public data: DataProvider,
    public router: Router,
    private actRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.errorCode = this.actRoute.snapshot.paramMap.get('error_code');
    this.renderer.addClass(document.querySelector('app-root'), 'email-confirm-page');
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'email-confirm-page'
    );
  }
}
