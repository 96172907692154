import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-corp-edit',
  templateUrl: './corp-edit.component.html',
  styleUrls: ['./corp-edit.component.scss'],
})
export class CorpEditComponent implements OnInit {
  public Corporation_No;
  public CompanyName;
  public CompanyNameKana;
  public PostCode;
  public Address1;
  public Address2;
  public Address3;
  public Telephone;  
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post
  public Staff_Position;
  public Users;
  public History_Flg;
  public Corporation_Form;
  public pdffile_name;
  public documentpdf;
  public CorporateStatus;
  public Acquired_Company_Code;
  public StartDate;
  public EndDate;
  public Mail;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.widthChanger('main-header', 942);
    this.data.menuPageTitle = 'contract-corp-reference';
    this.spinner.show();
    this.rest.Get_ContractsId(
        this.data.uuid,
        JSON.parse(localStorage.getItem('loginData')).data.uuid
      ).subscribe((res) => {
        console_log(res);
        this.Corporation_No = res.data.corporation_no;
        this.CompanyName = res.data.company_name;
        this.CompanyNameKana = res.data.company_name_kana;
        this.PostCode = res.data.postcode;
        this.Address1 = res.data.address_1;
        this.Address2 = res.data.address_2;
        this.Address3 = res.data.address_3;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.Telephone = res.data.tel;
        this.Users = res.data.users;
        this.History_Flg = res.data.history_flg;
        this.Corporation_Form = res.data.corporation_form;
        this.CorporateStatus = res.data.corporate_status;
        this.EndDate = res.data.end_date;
        this.StartDate = res.data.start_date;
        this.Mail = res.data.mail_address;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
  selectHistory(id){
    this.History_Flg = id;
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }
  
  dataValidation() {
    let err = false;
    let errTxt = '';
    let Number = /^-?\d*$/;
    if (this.Users == null || this.Users == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT')
        .subscribe((res) => (errTxt = res));
    }else if(this.Users != "" && !Number.test(this.Users)){
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name_Kana == null || this.Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name_Kana == null || this.Last_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name == null || this.Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  
  selectPDF() {
    const input: HTMLElement = document.getElementById('pdffile');
    input.click();
  }

  upload(event: Event) {
    const self: CorpEditComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      var pdf_string = reader.result.toString();
      var pdf_binary = pdf_string.split('data:application/pdf;base64,');
      window.IMAGE_RESULT = pdf_binary[1];
    };
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.pdffile_name = element.files[0].name;
    }
    element.value = '';
  }
  update() {
    if (!this.dataValidation()) {
      return true;
    } else {
      document.getElementById('error').innerHTML = '';
      document.getElementById('error').style.display = 'block';
      this.spinner.show();
      var Users: string = this.Users.toString();
      const body = {
        data:{
          user_uuid: this.data.uuid,
          uuid: JSON.parse(localStorage.getItem('loginData')).data.uuid,
          start_date: this.StartDate,
          end_date: this.EndDate,
          corporate_status: this.CorporateStatus,
          company_name: this.CompanyName,
          company_name_kana: this.CompanyNameKana,
          postcode: this.PostCode,
          address_1: this.Address1,
          address_2: this.Address2,
          address_3: this.Address3,
          tel: this.Telephone,
          mail_address: this.Mail,
          staff_last_name: this.Last_Name,
          staff_first_name: this.Name,
          staff_last_name_kana: this.Last_Name_Kana,
          staff_first_name_kana: this.Name_Kana,
          staff_post: this.Staff_Post,
          staff_position: this.Staff_Position,
          corporation_form: this.Corporation_Form,
          users: Users,
          acquired_code: this.Acquired_Company_Code,
          history_flg: this.History_Flg,
          call_flg: '1'
        }
      };
      console_log(body);
      this.rest.Update_Contracts(
        this.data.uuid,
        JSON.parse(localStorage.getItem('loginData')).data.uuid,
        this.StartDate,
        this.EndDate,
        this.CorporateStatus,
        this.CompanyName,
        this.CompanyNameKana,
        this.PostCode,
        this.Address1,
        this.Address2,
        this.Address3,
        this.Telephone,
        this.Mail,
        this.Last_Name,
        this.Name,
        this.Last_Name_Kana,
        this.Name_Kana,
        this.Staff_Post,
        this.Staff_Position,
        this.Corporation_Form,
        Users,
        this.Acquired_Company_Code,
        this.History_Flg,
        '1'
      ).subscribe(
        (res) => {
          console_log(res);
          this.router.navigate(['/contract-corp-reference']);
          this.spinner.hide();
        },
        (err) => {
          console_log(err);
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
          this.spinner.hide();
        }
      );
      if(window.IMAGE_RESULT){
        this.rest.Upload_Regcertificates(
          JSON.parse(localStorage.getItem('loginData')).data.uuid,
          this.data.uuid,
          window.IMAGE_RESULT).subscribe(
        (res) => {
          console_log(res)
          this.spinner.hide();
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
          }
        );
      }
    }
  }
  pdf_download() {
    this.rest.Download_Regcertificates(
      this.data.uuid, 
      JSON.parse(localStorage.getItem('loginData')).data.uuid
    ).subscribe(
      (res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      },
      (err) => {
        console_log(err);
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
}
declare global {
  interface Window {
    IMAGE_RESULT?: string;
  }
}
